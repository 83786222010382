import React from 'react';

function ImprintIT() {
  return (
    <div className="tm-imprint">
      <h1>Impressum.</h1>
      <p>
        <b>Responsabilità generale Trafimage.</b>
        <br />
        <br />
        FFS SA
        <br />
        Infrastruttura – Impianti e tecnologia – Operation Center Tecnica –
        Technical Management
        <br />
        Fachbus Trafimage
        <br />
        Poststrasse 6
        <br />
        3072 Ostermundingen
        <br />
        Svizzera
        <br />
        <a href="http://www.trafimage.ch">www.trafimage.ch</a>
        <br />
      </p>
      <p>
        <b>
          Coordinamento IT, dati sul trasporto pubblico, Pianta interattiva
          della stazione.
        </b>
        <br />
        <br />
        geOps AG
        <br />
        Solothurnerstrasse 235
        <br />
        4600 Olten
        <br />
        <a href="http://www.geops.ch">www.geops.ch</a>
        <br />
      </p>
      <p>
        <b>Realizzazione di cartine e piante delle stazioni.</b>
        <br />
        <br />
        evoq communications AG
        <br />
        Ottikerstrasse 59
        <br />
        8006 Zürich
        <br />
        <a href="http://www.evoq.ch">www.evoq.ch</a>
        <br />
      </p>
      <p>
        <b>Workspace piani delle stazioni.</b>
        <br />
        <br />
        multipol
        <br />
        Luegislandstrasse 105
        <br />
        8051 Zürich
        <br />
        <a href="http://www.multipol.ch">www.multipol.ch</a>
        <br />
      </p>
    </div>
  );
}

export default ImprintIT();
